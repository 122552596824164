import React, { Fragment } from "react";
import "./ServiceAppDevelopement.css";
import playStore from "../Images/playStore.png";
import appStore from "../Images/appStore.png";
import appManImg from "../Images/appManImg.png";

function ServiceAppDevelopement() {
  return (
    <Fragment>
      <div className="serviceWebsiteNavbarBack"></div>
      <div className="appDevelopmentFirstRowContainer">
        <div className="appDevelopmentSection">
          <section className="appDevelopmentContent">
            <h1>
              <span className="highlight">INNOVATIVE</span> APP DEVELOPMENT{" "}
              <br />
              SERVICES IN COIMBATORE |<br />
              <span className="highlight">TAILORED SOLUTIONS</span>
            </h1>
            <p>
              Looking for innovative app development services in Coimbatore?
              <br />
              BigBitezudio offers tailored mobile solutions to boost engagement
              and drive business growth. Contact us today!
            </p>
            <h3>OPTIMIZED FOR APP STORE & PLAY STORE</h3>
            <section className="storeButtons">
              <img src={playStore} alt="Get it on Google Play" />
              <img src={appStore} alt="Download on the App Store" />
            </section>
          </section>
        </div>
        <div className="appDevelopmentFirstRowImgContainer">
        <img src={appManImg} alt="appManImg" className="appManImg" />
        <h2>Build. Launch. Succeed</h2>
        <p>
          At BigBitezudio, an expert{" "}
          <b>app development company in Coimbatore,</b> we build custom mobile
          apps that boost user engagement and fuel business growth. Our
          scalable, intuitive solutions are crafted to fit your unique needs.
        </p>
      </div>
      </div>

   
    </Fragment>
  );
}

export default ServiceAppDevelopement;
