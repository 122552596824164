import React from "react";
import "./ContactUs.css";
import contactUsPageBkImg from "../Images/contacUsPageBkImg.png";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
function ContactUs() {
  return (
    <div style={{ overflow: "hidden" }}>
      <Helmet>
        <title>Contact BigBitezudio | Get in Touch for Digital Solutions</title>
        <meta
          name="description"
          content="Contact BigBitezudio, a top digital marketing, web & app development company in Coimbatore. Let's discuss how we can help grow your business."
        />
        <link rel="canonical" href="https://bigbitezudio.in/ContactUs" />
      </Helmet>

      <div className="serviceWebsiteNavbarBack"></div>
      <div className="contactUsContainer">
        <img
          src={contactUsPageBkImg}
          alt="contactUsBackgroundImage"
          className="contactUsBackgroundImage"
        />
        <div className="aboutUsBackgroundImageHeadings">
          <h1>
            Get in Touch with <br />
            <span>BigBiteZudio</span>
          </h1>
          <p>
            At <b>BigBitezudio</b>, we help grow your business with expert
            digital solutions.
          </p>
          <Button
            variant="light"
            onClick={() => {
              // Replace with your phone number
              const phoneNumber = "+916380521667";
              // Redirect to phone dialer
              window.location.href = `tel:${phoneNumber}`;
            }}
          >
            Contact Us Today
          </Button>
        </div>
      </div>

      <div className="contactUsSecondRowDiv">
        <h2>
          Want to make your business better? <span>Contact us today!</span>
        </h2>
        <p>
          At <b>BigBitezudio,</b> we’re ready to help you take your business to
          the next level. Whether you need expert guidance on digital marketing,
          custom SaaS development, app development, or web development, our team
          is here to assist you.
        </p>
      </div>

      <iframe
        className="iframeContainer"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.162586773733!2d76.98710657355574!3d11.026425454535715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85974c21ae915%3A0xed0afe6c6a9c3797!2sbigbitezudio!5e0!3m2!1sen!2sin!4v1727367875050!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: "0" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      <h2 className="contactUsGetInTouchWithUs">GET IN TOUCH WITH US</h2>

      <h2 className="assistHeading">
        How we can assist <span>you?</span>
      </h2>

      <section className="contactFormSection">
        <form className="contactUsForm">
          <div className="formRow">
            <div className="formGroup">
              <label htmlFor="name">Enter Your Name</label>
              <input
                type="text"
                placeholder="Please enter your full name"
                name="name"
                id="name"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="number"
                placeholder="Enter your phone number"
                name="phoneNumber"
                id="phoneNumber"
              />
            </div>
          </div>

          <div className="formRow">
            <div className="formGroup">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                placeholder="Enter your email address"
                name="email"
                id="email"
              />
            </div>

            <div className="formGroup">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                placeholder="Add subject here"
                name="subject"
                id="subject"
              />
            </div>
          </div>

          <div className="formGroup">
            <label htmlFor="message">Your Message</label>
            <textarea
              placeholder="Describe your message here"
              id="message"
              name="message"
            />
          </div>
          <div className="submitButtonDiv">
            <input
              type="submit"
              value="Send Message"
              className="submitButton"
            />
          </div>
        </form>
      </section>
    </div>
  );
}

export default ContactUs;
