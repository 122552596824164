import "./App.css";
import Home from "./Components/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "./Components/NavigationBar";
import Footer from "./Components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ServiceDigitalMarketing from "./Components/ServiceDigitalMarketing";
import AboutUs from "./Components/AboutUs";
import ServiceWebsite from "./Components/ServiceWebsite";
import ServiceSaas from "./Components/ServiceSaas";
import HomePage from "./Components/HomePage";
import ContactUs from "./Components/ContactUs";
import { HelmetProvider } from "react-helmet-async";
import ServiceAppDevelopement from "./Components/ServiceAppDevelopement";

function App() {
  return (
    <div>
      <HelmetProvider>
        <BrowserRouter>
          <NavigationBar />
          <Routes>
            <Route path="/Home" element={<Home />} />
            <Route path="/" element={<HomePage />} />
            <Route
              path="/Service/DigitalMarketing"
              element={<ServiceDigitalMarketing />}
            />
          
            <Route
              path="/Service/Website-Developement"
              element={<ServiceWebsite />}
            />
            <Route
              path="/Service/Software-as-a-Service"
              element={<ServiceSaas />}
            />
                <Route
              path="/Service/App-Development"
              element={<ServiceAppDevelopement/>}
            />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/AboutUs" element={<AboutUs />} />
          </Routes>

          {/* <Footer /> */}
        </BrowserRouter>
      </HelmetProvider>
  
    </div>
  );
}

export default App;
